import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "icon"]

  toggle(e) {
    e.preventDefault()
    e.stopPropagation()
    this.contentTarget.classList.contains("collapse-closed")
      ? this.open(e)
      : this.close(e)
  }

  open(e) {
    this.contentTarget.classList.remove("collapse-closed")
    this.contentTarget.classList.add("collapse-opened")
    this.iconTarget.classList.add("rotate-90")
  }

  close(e) {
    this.contentTarget.classList.remove("collapse-opened")
    this.contentTarget.classList.add("collapse-closed")
    this.iconTarget.classList.remove("rotate-90")
  }
}
