import IMask from "imask"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["numbers3", "year", "phone", "birthdate", "heightUs"]

  connect() {
    this.numbers3Targets.forEach((target) => {
      IMask(target, {
        mask: "000,0",
      })
    })

    this.yearTargets.forEach((target) => {
      IMask(target, {
        mask: IMask.MaskedRange,
        from: 1990,
        to: 2020,
      })
    })

    this.birthdateTargets.forEach((target) => {
      IMask(target, {
        mask: Date,
        pattern: "d{/}`m{/}`Y", // default is 'd{.}`m{.}`Y'
        format: function (date) {
          var day = date.getDate()
          var month = date.getMonth() + 1
          var year = date.getFullYear()

          if (day < 10) day = "0" + day
          if (month < 10) month = "0" + month
          return [day, month, year].join("/")
        },
        parse: function (str) {
          var yearMonthDay = str.split("/")
          var date = new Date(yearMonthDay[2], yearMonthDay[1] - 1, yearMonthDay[0])
          return date
        },
        lazy: true,
      })
    })

    this.phoneTargets.forEach((target) => {
      IMask(target, {
        mask: "00.00.00.00.00",
      })
    })

    // new Cleave('.is-format-birthdate', {
    //   date: true,
    //   delimiter: '-',
    //   datePattern: ['d', 'm', 'Y']
    // });

    // new Cleave('.is-format-birthyear', {
    //   date: true,
    //   datePattern: ['Y']
    // });

    // if ($('.is-format-height-us').length > 0) {
    //   new Cleave('.is-format-height-us', {
    //     numericOnly: true,
    //     delimiters: ['\'', '\"'],
    //     blocks: [1, 2],
    //   });
    // }

    // if ($('.is-format-salary').length > 0) {
    //   new Cleave('.is-format-salary', {
    //     numericOnly: true,
    //     blocks: [3]
    //   });
    // }

    // if ($('.is-format-salary-roster').length > 0) {
    //   $('.is-format-salary-roster').toArray().forEach(function(field){
    //     new Cleave(field, {
    //       numeral: true,
    //       numeralDecimalMark: ',',
    //       delimiter: ' '
    //     });
    //   })
    // }

    // if ($('.is-format-weight').length > 0) {
    //   new Cleave('.is-format-weight', {
    //     numericOnly: true,
    //     blocks: [3]
    //   });
    // }

    // if ($('.is-format-height').length > 0) {
    //   new Cleave('.is-format-height', {
    //     numericOnly: true,
    //     blocks: [3]
    //   });
    // }
  }
}
