import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "mental",
    "autonomy",
    "game",
    "potential",
    "environment",
    "school",
    "totalMental",
    "totalAutonomy",
    "totalGame",
    "totalPotential",
    "totalEnvironment",
    "totalSchool",
    "total",
  ]

  connect() {
    this.updateNotes()
  }

  updateNotes() {
    let totalMental = 0
    let totalAutonomy = 0
    let totalGame = 0
    let totalPotential = 0
    let totalEnvironment = 0
    let totalSchool = 0

    let itemsMental = this.mentalTargets.length
    let itemsAutonomy = this.autonomyTargets.length
    let itemsGame = this.gameTargets.length
    let itemsPotential = this.potentialTargets.length
    let itemsEnvironment = this.environmentTargets.length
    let itemsSchool = this.schoolTargets.length

    if (this.hasMentalTarget) {
      this.mentalTargets.forEach((target) => {
        let checked = target.querySelector("input:checked")
        totalMental += parseInt(checked.value)
      })
    }

    if (this.hasAutonomyTarget) {
      this.autonomyTargets.forEach((target) => {
        let checked = target.querySelector("input:checked")
        totalAutonomy += parseInt(checked.value)
      })
    }

    if (this.hasGameTarget) {
      this.gameTargets.forEach((target) => {
        let checked = target.querySelector("input:checked")
        totalGame += parseInt(checked.value)
      })
    }
    if (this.hasPotentialTarget) {
      this.potentialTargets.forEach((target) => {
        let checked = target.querySelector("input:checked")
        totalPotential += parseInt(checked.value)
      })
    }
    if (this.hasEnvironmentTarget) {
      this.environmentTargets.forEach((target) => {
        let checked = target.querySelector("input:checked")
        totalEnvironment += parseInt(checked.value)
      })
    }
    if (this.hasSchoolTarget) {
      this.schoolTargets.forEach((target) => {
        let checked = target.querySelector("input:checked")
        totalSchool += parseInt(checked.value)
      })
    }
    this.totalMentalTarget.innerText = totalMental + " / " + itemsMental * 10

    this.totalAutonomyTarget.innerText =
      totalAutonomy + " / " + itemsAutonomy * 10

    this.totalGameTarget.innerText = totalGame + " / " + itemsGame * 10

    this.totalPotentialTarget.innerText =
      totalPotential + " / " + itemsPotential * 10

    this.totalEnvironmentTarget.innerText =
      totalEnvironment + " / " + itemsEnvironment * 10

    this.totalSchoolTarget.innerText = totalSchool + " / " + itemsSchool * 10

    this.totalTarget.innerText =
      totalMental +
      totalAutonomy +
      totalGame +
      totalPotential +
      totalEnvironment +
      totalSchool +
      " / " +
      (itemsMental +
        itemsAutonomy +
        itemsGame +
        itemsPotential +
        itemsEnvironment +
        itemsSchool) *
        10
  }
}
