import { enter, leave } from 'el-transition';
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["wrapper", "overlay", "sideBar", "closeButton"]

  open() {
    this.wrapperTarget.style.removeProperty('display')
    enter(this.wrapperTarget)
    enter(this.overlayTarget)
    enter(this.sideBarTarget)
    enter(this.closeButtonTarget)
  }
  
  close(){
    leave(this.overlayTarget)
    leave(this.sideBarTarget)
    leave(this.closeButtonTarget).then(() => {
      this.wrapperTarget.style['display'] = 'none'
    })
  }
}